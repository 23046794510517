import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
export interface AmountState {
  voteCount: number
  amount: number
  metadata: string
  nominee_id?: number
}
const initialState: AmountState = {
  voteCount: 0,
  amount: 0,
  metadata: ''
};
const AmountSlice = createSlice({
  name: 'votes',
  initialState,
  reducers: {
    updateSlice (state: AmountState, action: PayloadAction<AmountState>) {
      state.voteCount = action.payload.voteCount;
      state.amount = action.payload.amount;
      state.metadata = action.payload.metadata;
      state.nominee_id = action.payload.nominee_id;
    }
  }
});
export const { updateSlice } = AmountSlice.actions;
export default AmountSlice;
