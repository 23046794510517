import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
export interface AppState {
  votes: number
  candidate?: string
  votePower?: number
}
const initialState: AppState = {
  votes: 0,
  candidate: '',
  votePower: 0
};
const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateVote (state: AppState, action: PayloadAction<AppState>) {
      state.votes -= action.payload.votes;
    },
    addVotes (state: AppState, action: PayloadAction<AppState>) {
      state.votes += action.payload.votes;
    },
    selectCandidate (state: AppState, action: PayloadAction<AppState>) {
      state.candidate = action.payload.candidate;
    },
    updateVotePower (state: AppState, action: PayloadAction<number>) {
      state.votePower = action.payload;
    }
  }
});
export const { updateVote, addVotes, selectCandidate, updateVotePower } = AppSlice.actions;
export default AppSlice;
