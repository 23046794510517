/* eslint-disable react/prop-types */
import '../styles/globals.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import React from 'react';

import { Analytics } from '@vercel/analytics/react';
import type { NextComponentType, NextPage } from 'next'; // Import Component type
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { Provider } from 'react-redux';

import store from '../utils/services/store';

// Add custom appProp type then use union to add it
type CustomAppProps = AppProps & {
  Component: NextComponentType & { auth?: boolean } // add auth type
}
// If loading a variable font, you don't need to specify the font weight
const inter = Inter({
  subsets: ['latin'],
  display: 'swap'
});

const MyApp: NextPage<CustomAppProps> = ({
  Component,
  pageProps: { session, ...pageProps }
}) => {
  const router = useRouter();
  return (
    <Provider store={store}>
      <SessionProvider session={session}>
          <Component inter={inter} key={router.asPath} {...pageProps} />
          <Analytics />
      </SessionProvider>
    </Provider>
  );
};

export default MyApp;
