import { configureStore } from '@reduxjs/toolkit';

import AmountSlice from './AmountSlice';
import connectedAwardsApi from './ApiConnection';
import AppSlice from './AppSlice';
import connectedAwardsEditionsApi from './EditionsApiConnection';

const store = configureStore({
  reducer: {
    [AppSlice.name]: AppSlice.reducer,
    [AmountSlice.name]: AmountSlice.reducer,
    [connectedAwardsApi.reducerPath]: connectedAwardsApi.reducer,
    [connectedAwardsEditionsApi.reducerPath]: connectedAwardsEditionsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(connectedAwardsApi.middleware, connectedAwardsEditionsApi.middleware)
});
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
