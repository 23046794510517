import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const ApiHeaders = {
  Accept: 'application/json',
};
const baseUrl = 'https://www.awardtest.techtrend.africa/api';
interface AllParticipantsQuery {
  status: boolean;
  message: string;
  data: {
    current_page: number;
    data: Array<{
      id: number;
      full_name: string;
      linkedin: string;
      category: string;
      edition: string;
      created_at: Date;
      updated_at: Date;
    }>;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
  };
}
const createRequest = (
  url: string,
  method: any
): { url: string; headers: Record<string, string>; method: string } => ({
  url,
  headers: ApiHeaders,
  method,
});
const connectedAwardsApi = createApi({
  reducerPath: 'EditorsApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ['Post', 'Get'],

  endpoints: (builder) => ({
    getCelebratedTechies: builder.query({
      query: () => createRequest('/celebrated-techies', 'GET'),
      providesTags: ['Get'],
    }),
    getWinners: builder.query({
      query: () => createRequest('/winners', 'GET'),
      providesTags: ['Get'],
    }),
    getAllParticipants: builder.mutation({
      query: (index: number) =>
        createRequest(`/all-participants?page=${index}`, 'GET'),
      transformResponse(res: AllParticipantsQuery) {
        const data = res.data.data.map((item) => ({
          Id: item.id,
          Name: item.full_name,
          Linkedin: item.linkedin,
          Category: item.category,
          Edition: item.edition,
          user_image_url: undefined,
          user_image: undefined,
        }));
        const current_page = res.data.current_page;
        const last_page = res.data.last_page;
        return {
          data,
          current_page,
          last_page,
        };
      },
    }),
  }),
});
export const {
  useGetCelebratedTechiesQuery,
  useGetWinnersQuery,
  useGetAllParticipantsMutation,
} = connectedAwardsApi;
export default connectedAwardsApi;
